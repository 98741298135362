// 组件的配置信息

export default {
  // 属性配置示例:
  // exmaple: {
  //     // 配置中显示的标题
  //     title: '',
  //     // 功能说明
  //     tooltip: '',
  //     // 提供的示例数据
  //     sampleData: {},
  //     // 默认value 的初始值
  //     defaultValue: {},
  //     // 是否必须 (组件只能有1 个, 且不能删除, 不能隐藏)
  //     must: false,
  //     // 最大数量限制
  //     limit: 1,
  //     // 固定位置的排序(从1 开始)
  //     fixedSort: 0,
  //     // 验证方法, 返回非空表示验证失败
  //     // eslint-disable-next-line
  //     validate (item) { }
  // },
  templateHead : {
    title : '标题栏',
    img1 : require( '@/assets/tempticon/1.png' ),
    img2 : require( '@/assets/tempticon/1(1).png' ),
    type : 'templateHead',
    defaultValue : {
      title : '',
      icon : '',
      bgcolor : '#004da1'
    },
    must : false,
    limit : 1,
    validate( item ) {
      if ( !item.value.title ) {
        return '请输入标题'
      }
      // if (!item.value.icon) {
      //     return '请选择图标'
      // }
    }
  },
  carousel : {
    title : '轮播图',
    img1 : require( '@/assets/tempticon/3.png' ),
    img2 : require( '@/assets/tempticon/3(1).png' ),
    type : 'carousel',
    defaultValue : {
      height : '200px',
      images : []
    },
    must : false,
    limit : 1,
    validate( item ) {
      if ( !item.value.images.length ) {
        return '请选择轮播图图片'
      }
      if ( item.value.url != null && item.value.url != '' && !item.value.url.match( /^https?:\/\// ) ) {
        return '自定义的链接需要填写带http:// 或者https:// 的有效连接'
      }
    }
  },
  text : {
    title : '文本',
    img1 : require( '@/assets/tempticon/5.png' ),
    img2 : require( '@/assets/tempticon/5(1).png' ),
    tooltip : '可新增多个',
    type : 'text',
    defaultValue : {
      text : '',
      style : 'white-space: pre-wrap;line-height:1.5;text-align:left'
    },
    must : false,
    limit : 10,
    validate( item ) {
      const reg = /^(?! |.* {2})[^#+&\'\"\\\\]*$/
      if ( !item.value.text ) {
        return '请输入文本'
      }
      if ( !reg.test( item.value.text ) ) {
        return '开头不能为空格'
      }
    }
  },
  video : {
    title : '视频',
    img1 : require( '@/assets/tempticon/6.png' ),
    img2 : require( '@/assets/tempticon/6(1).png' ),
    tooltip : '可新增多个',
    type : 'video',
    defaultValue : {
      src : ''
    },
    must : false,
    limit : 4,
    validate( item ) {
      if ( !item.value.src ) {
        return '请上传视频'
      }
    }
  },
  pictures : {
    title : '图片',
    img1 : require( '@/assets/tempticon/7.png' ),
    img2 : require( '@/assets/tempticon/7(1).png' ),
    tooltip : '可新增多个',
    type : 'pictures',
    defaultValue : {
      images : [],
      link : ''
    },
    must : false,
    limit : 10,
    validate( item ) {
      if ( item.value.images.length == 0 ) {
        return '请至少上传1 张图片'
      }
      const link = item.value.link
      if ( link && !link.match( /^https?:\/\// ) ) {
        return '自定义的链接需要填写带http:// 或者https:// 的有效连接'
      }
    }
  },
  companyInfo : {
    title : '企业信息',
    img1 : require( '@/assets/tempticon/2.png' ),
    img2 : require( '@/assets/tempticon/2(1).png' ),
    tooltip : '展示企业信息',
    type : 'companyInfo',
    sampleData : {
      batch : {
        displayOption : ''
      }
    },
    must : false,
    limit : 1,
    defaultValue : {}
    // validate(item) {
    //   if (!item.value.companyName || item.value.companyName == '' || item.value.companyName == undefined) {
    //     return '请填写企业名称'
    //   } else if (!item.value.companyCategory || item.value.companyCategory == '' || item.value.companyCategory == undefined) {
    //     return '请填写企业分类'
    //   } else if (!item.value.companyAddress || item.value.companyAddress == '' || item.value.companyAddress == undefined) {
    //     return '请填写企业地址            '
    //   } else if (!item.value.companyBusiness || item.value.companyBusiness == '' || item.value.companyBusiness == undefined) {
    //     return '请填写经营范围'
    //   }
    // }
  },
  productInfo : {
    title : '产品信息',
    img1 : require( '@/assets/tempticon/4.png' ),
    img2 : require( '@/assets/tempticon/4(1).png' ),
    tooltip : '展示产品明细中配置的产品属性数据',
    type : 'productInfo',
    sampleData : {
      batch : {
        displayOption : ''
      }
    },
    must : false,
    limit : 1,
    defaultValue : {},
    validate( item ) {
      if ( !item.value.productName || item.value.productName == '' || item.value.productName == undefined ) {
        return '请填写产品名称'
      } else if ( !item.value.categoryName || item.value.categoryName == '' || item.value.categoryName == undefined ) {
        return '请填写产品分类'
      }
    }
  },
  stylebox : {
    title : '宫格',
    img1 : require( '@/assets/tempticon/8.png' ),
    img2 : require( '@/assets/tempticon/8(1).png' ),
    tooltip : '可新增多个',
    type : 'stylebox',
    must : false,
    limit : 16,
    defaultValue : {
      rows : [
        [{
          size : 1,
          title : '标题',
          linkType : 'none',
          href : '',
          fonticon : 'el-icon-menu',
          imgIcon : '',
          forecolor : '#FFFFFF',
          titleAlign : 'center',
          backcolor : '#004da1'
        },
        {
          size : 1,
          title : '标题',
          linkType : 'none',
          href : '',
          fonticon : 'el-icon-menu',
          imgIcon : '',
          forecolor : '#FFFFFF',
          titleAlign : 'center',
          backcolor : '#004da1'
        },
        {
          size : 1,
          title : '标题',
          linkType : 'none',
          href : '',
          fonticon : 'el-icon-menu',
          imgIcon : '',
          forecolor : '#FFFFFF',
          titleAlign : 'center',
          backcolor : '#004da1'
        }
        ]
      ]
    },
    validate( item ) {
      const row = item.value.rows[0]
      for ( const cell of row ) {
        if ( cell.linkType === 'normal' ) {
          if ( !cell.href || !cell.href.match( /^https?:\/\// ) ) {
            return '自定义的链接需要填写带http:// 或者https:// 的有效连接'
          }
        } else if ( cell.linkType === 'qrCodeScan' ) {
          if ( !cell.qrCodeImage ) {
            return '关注微信链接需要上传二维码'
          }
        } else if ( cell.linkType === 'kefuMobile' ) {
          if ( !cell.kefuMobile ) {
            return '请填写电话号码'
          }
        }
      }
    }
  },
  codeInformation : {
    title : '标识码',
    img1 : require( '@/assets/tempticon/9.png' ),
    img2 : require( '@/assets/tempticon/9(1).png' ),
    tooltip : '展示标识码和其在二级节点的同步状态',
    type : 'codeInformation',
    defaultValue : {},
    sampleData : {
      code : {
        idisCode : '88.118.8/KIT001'
      }
    },
    limit : 1,
    must : false
  }
  // weixinUserInfo: {
  //   title: '获取用户',
  //   img1: require('@/assets/tempticon/10.png'),
  //   img2: require('@/assets/tempticon/10(1).png'),
  //   tooltip: '获取扫描用户的微信用户信息',
  //   type: 'weixinUserInfo',
  //   defaultValue: {},
  //   sampleData: {},
  //   limit: 1,
  //   must: false
  // },
}
